<template>
  <v-card
    no-gutter
    :style="
      'width: 100% !important; box-sizing: border-box; max-height: 100% !important; overflow: hidden;' +
      (fakeData ? 'height: 500px;' : 'height: 100% !important;') +
      (soMetaBackgroundColorCard
        ? 'background-color: ' + soMetaBackgroundColorCard + ';'
        : '')
    "
    class="align-center justify-center text-center"
  >
    <v-toolbar dense flat style="background: transparent">
      <v-toolbar-title
        :style="soMetaTextColorTitle ? 'color: ' + soMetaTextColorTitle : ''"
        >{{ module.title }}</v-toolbar-title
      >
    </v-toolbar>
    <v-container
      fill-height
      align="center"
      style="max-width: 100% !important; margin: 0 auto; height: 100%"
      justify="center"
    >
      <v-row
        style="
          max-width: 100% !important;
          margin: 0;
          max-height: 100%;
          height: 100%;
        "
        align="center"
        justify="center"
      >
        <v-img
          v-if="imageAvailable && SO_API_BASE_URL != undefined"
          contain
          max-height="100%"
          :src="SO_API_BASE_URL + '/image/' + computedPath"
        ></v-img>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Meta from "@/_helpers/ModuleMeta";

export default {
  name: "ModImage",

  mixins: [Meta],

  data() {
    return {
      mod: null,
      path: null,
      setupDone: false,
    };
  },

  props: {
    mode: Number,
    module: Object,
    fakeData: Boolean,
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),

    computedPath() {
      if (this.module == null || this.module.image == null) return null;

      return this.module.image.path;
    },

    imageAvailable() {
      return this.computedPath !== null;
    },
  },

  methods: {
    ...mapActions("configuration", ["getBaseUrl"]),

    setData() {
      this.setupDone = true;
    },
  },

  async created() {
    await this.getBaseUrl();
    this.setData();
  },
};
</script>

<style></style>
